import React from "react";
import { connect } from "react-redux";
import withTranslation from "../hocs/withTranslation";
import PrepaidCardView from "../components/PrepaidCardView";
import {
  getCashbackDiscounts,
  getCurrentPaymentMethodDetails,
  getStyles,
} from "../store/selectors/index";
import {
  loadLoyaltyProfile,
  loadPaymentMethods,
  rechargeCardReset,
} from "../store/user/actions";
import { rechargeCard } from "../store/order/actions";
import { get } from "lodash";

const PrePaidPage = withTranslation(
  ({
    T,
    location,
    pageContext: { business, businessAppConfiguration },
    user,
    order,
    chargeCard,
    loadCard,
    cashbackDiscounts,
    loadLoyaltyProfile,
    keyboardOpen,
    loadPaymentMethods,
    paymentTypeDetails,
    rechargeCard,
    rechargeCardReset,
    appStyles,
  }) => (
    <PrepaidCardView
      T={T}
      location={location}
      user={user}
      order={order}
      business={business}
      businessAppConfiguration={businessAppConfiguration}
      cashbackDiscounts={cashbackDiscounts}
      loadLoyaltyProfile={loadLoyaltyProfile}
      keyboardOpen={keyboardOpen}
      paymentTypeDetails={paymentTypeDetails}
      loadPaymentMethods={loadPaymentMethods}
      rechargeCard={rechargeCard}
      rechargeCardReset={rechargeCardReset}
      appStyles={appStyles}
    />
  ),
);

const mapStateToProps = (state, props) => {
  const {
    user,
    order,
    app: { keyboardOpen },
  } = state;
  return {
    user,
    order,
    keyboardOpen,
    cashbackDiscounts: getCashbackDiscounts(state, props),
    paymentTypeDetails: getCurrentPaymentMethodDetails(state, props),
    appStyles: getStyles(state, props),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const {
    pageContext: {
      businessAppConfiguration: { hasCashback, loadCardEnabled },
      business,
    },
  } = props;

  const currency = get(business, "openChargeCard.price.currency", 0);
  const chargeCardBasePrice = get(business, "openChargeCard.price");
  return {
    loadLoyaltyProfile: (force) => dispatch(loadLoyaltyProfile(force)),
    loadPaymentMethods: (paymentTypeIdentifier) =>
      dispatch(loadPaymentMethods(paymentTypeIdentifier, currency)),
    rechargeCard: ({ customerDetails, cvv, creditCard, paymentTypeDetails }) =>
      dispatch(
        rechargeCard({
          customerDetails,
          cvv,
          paymentTypeDetails,
          currency,
          chargeCardBasePrice,
          creditCard,
        }),
      ),
    rechargeCardReset: () => dispatch(rechargeCardReset()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrePaidPage);
